function cutAddress(address) {
  if (address) {
    var addressEnd = address.substring(address.length - 4);
    var addressStart = address.slice(0, 6);
    return {
      start: addressStart,
      end: addressEnd
    };
  }
}

export { cutAddress };